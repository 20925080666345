import React from 'react';
import {FaGithub} from "react-icons/fa";
import {CgFileDocument} from "react-icons/cg";


const  ProjectBox = ({projectPhoto, projectName}) => {
  const desc = {
    "GoogleDocs Clone Desc" : "Google Docs Clone is the Clone of Existsing Google Docs. It will have all the features similar to Google Docs. I am currently working on it.",
    "GoogleDocs Clone Github": "https://github.com/Omkar-Ghongade/login-signup",

    "Web3Verse Desc" : "Web3Verse is a blockchain-powered VR ecosystem that combines social interaction and digital commerce, featuring land ownership, NFT trading, gaming, and custom avatars.",
    "Web3Verse Github" : "https://github.com/Omkar-Ghongade/Web3Verse",

    "Hashtag Caption Generator Desc":"Created an innovative hashtag and caption generator, employing Flask, machine learning algorithms, and a range of APIs and Google Cloud Services to boost user engagement on social media platforms.",
    "Hashtag Caption Generator Github":"https://github.com/Omkar-Ghongade/hashtag-emoji-caption-gen",
    
    "Code Explainer Desc":"Developed a web application that explains the code of any programming language in a simple and easy-to-understand manner, using Angular, Flask, and ChatGPT API.",
    "Code Explainer Github":"https://github.com/Omkar-Ghongade/Code-Explainer",
  }

  let show ='';
  if(desc[projectName + 'Github']===""){
    show="none";
  }
    
  return (
    <div className='projectBox'> 
        <img className='projectPhoto' src={projectPhoto} alt="Project display" /> 
        <div>
            <br />
            <h3>{projectName}</h3>
            <br />
            {desc[projectName + ' Desc']}
            <br />

            <a style={{display:show}} href={desc[projectName + ' Github']} target='_blank'>
              <button className='projectbtn'><FaGithub/> Github</button>
            </a>
        </div>
    </div>
  )
}

export default  ProjectBox