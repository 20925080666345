import React from 'react';
import {FaGithub, FaLinkedin} from "react-icons/fa";
import {GrMail} from "react-icons/gr";
import {SiLeetcode} from "react-icons/si"

const Footer = () => {
  return (
    <footer>
      <h4>Developed by Omkar Ghongade</h4>
      
      <div className='footerLinks'>
        <a href="https://github.com/Omkar-Ghongade" target='_blank'><FaGithub/></a>
        <a href="https://www.linkedin.com/in/omkar-ghongade/" target='_blank'><FaLinkedin/></a>
        <a href='mailTo:omkarsubhashghongade21@gmail.com' target='_blank'><GrMail/></a>
        <a href="https://leetcode.com/omkarsubhashghongade21/" target="_blank"><SiLeetcode/></a>
      </div>
    </footer>
  )
}

export default Footer