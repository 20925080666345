import React from 'react';
import ProjectBox from './ProjectBox';
import GoogleDocs from '../images/img1.png';
import webverse from '../images/img2.png';
import hashtags from '../images/img3.png';
import codeexplain from '../images/img4.png';

const Projects = () => {
  return (
    <div>
      <h1 className='projectHeading'>My <b>Projects</b></h1>
      <div className='project'>
        <ProjectBox projectPhoto={GoogleDocs} projectName="GoogleDocs Clone" />
        <ProjectBox projectPhoto={webverse} projectName="Web3Verse" />
        <ProjectBox projectPhoto={hashtags} projectName="Hashtag Caption Generator" />
        <ProjectBox projectPhoto={codeexplain} projectName="Code Explainer" />
      </div>

    </div>
  )
}

export default Projects